import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routeToEvent } from '@events/routes';
import { EventsService } from '@shared/services/events.service';
import { EventCriteriaSearchModel } from '@events/models';
import { EventsDashBoardGridModel } from '@home/models/spiEventGrid.model';
import { routeToValuation } from '@valuation/routes';
import { ValuationCriteriaSearchModel } from '@valuation/models';
import { routeToPayment } from '@payment/routes';
import { PaymentCriteriaSearchModel } from '@payment/models';
import { VALUATION_DASHBOARD_QUERY_REJECT_REASONS } from '@valuation/configs/reject-reasons';

@Component({
  selector: 'app-spi-event-grid',
  templateUrl: './spi-event-grid.component.html'
})
export class SpiEventGridComponent implements OnInit {
  public columnDefs: ColDef[] = [{ field: 'status', minWidth: 200 }, { field: 'value' }];
  public staColumnDefs: ColDef[] = [{ field: 'status', minWidth: 200 }, { field: 'subStatus', minWidth: 200 }, { field: 'value' }];
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true
  };
  public groupDisplayType = 'groupRows';
  public spiRowData!: EventsDashBoardGridModel[];
  public staRowData!: EventsDashBoardGridModel[];
  private gridApi!: GridApi;
  public gridOptions!: GridOptions;

  constructor(private readonly router: Router, private readonly eventsService: EventsService) {}

  ngOnInit() {
    this.gridOptions = {
      overlayLoadingTemplate: '<span > <i class="fa fa-spinner fa-pulse fa-2x"></i></span>'
    };
    this.gridApi?.showLoadingOverlay();
    this.eventsService.getDashBoardValues().subscribe(res => {
      if (res?.response) {
        this.spiRowData = [{ status: 'Notification - To be input' }, { status: 'Notification - To be validated' }, { status: 'To be paid' }, { status: 'Events blocked' }, { status: 'VD to VD+2 - Not closed' }];
        this.staRowData = [
          { status: 'Total events' },
          { status: 'VD-2 control' },
          { subStatus: 'CCY Part 1 (exotic) list' },
          { subStatus: 'CCY Part 2 list' },
          { status: 'Event to be validated' },
          { status: 'Valuation in repair' },
          { status: 'Events not reconciled' },
          { status: 'Valuation cancel' },
          { status: 'Valuation update' },
          { status: 'Await new valuation' },
          { status: 'Total payments' },
          { subStatus: 'CCY Part 1 (exotic) list ' },
          { subStatus: 'CCY Part 2 list ' },
          { status: 'Events in repair /failed' },
          { status: 'Await new payment' },
          { status: 'Markdown pending' },
          { status: 'Alert - Events blocked' },
          { status: 'Partial payments' }
        ];
        this.spiRowData[0].value = res.response['Notification - to be input'];
        this.spiRowData[1].value = res.response['Notification - to be validated'];
        this.spiRowData[2].value = res.response['To be paid'];
        this.spiRowData[3].value = res.response['Events Blocked'];
        this.spiRowData[4].value = res.response['VD to VD+2 - Not Closed'];

        this.staRowData[0].value = res.response['Valuation - Total Events'];
        this.staRowData[2].value = res.response['VD-2 control exotic currency'];
        this.staRowData[3].value = res.response['VD-2 control normal currency'];
        this.staRowData[4].value = res.response['Event to be validated'];
        this.staRowData[5].value = res.response['Valuation in repair'];
        this.staRowData[6].value = res.response['Not reconciled'];
        this.staRowData[7].value = res.response['Valuation Cancel'];
        this.staRowData[8].value = res.response['Valuation Update'];
        this.staRowData[9].value = res.response['Await new valuation'];
        this.staRowData[11].value = res.response['Total payment exotic currency'];
        this.staRowData[12].value = res.response['Total payment normal currency'];
        this.staRowData[13].value = res.response['In repair/failed'];
        this.staRowData[14].value = res.response['Await new payment'];
        this.staRowData[15].value = res.response['Markdown Pending'];
        this.staRowData[16].value = res.response['Alert - Events Blocked'];
        this.staRowData[17].value = res.response['Partial payment'];
      }
      this.gridApi?.hideOverlay();
    });
  }

  onSPIGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
  }

  onSelectionChanged(params: any) {
    if (params.api.getSelectedRows().length > 0) {
      const values = params.api.getSelectedRows()[0].status;
      const subStatusValues = params.api.getSelectedRows()[0].subStatus;
      const { plusTwoBusinessDays } = this.plusTwoBusinessDaysSearch();
      const { threeMonthMinusTodayDate, todayDate } = this.threeMonthMinusTodaysDateSearch();
      let query: EventCriteriaSearchModel = {};
      let valuationQuery: ValuationCriteriaSearchModel = {};
      let paymentsQuery: PaymentCriteriaSearchModel = {};
      let filterParams = '';
      switch (subStatusValues) {
        case 'CCY Part 1 (exotic) list':
          query = {
            eventStatuses: [
              { valueId: 1, valueDescription: 'FIXING DATE TO BE REACHED' },
              { valueId: 2, valueDescription: 'EVENT TO BE INPUT' },
              { valueId: 7, valueDescription: 'VALUATION IN REPAIR' }
            ],
            issuerPerimter: 'STA',
            paymentCurrencies: ['AED', 'AUD', 'BGN', 'BHD', 'CNY', 'HKD', 'HRK', 'ILS', 'INR', 'ISK', 'JPY', 'KWD', 'MAD', 'NZD', 'OMR', 'QAR', 'RSD', 'SAR', 'SGD', 'THB', 'TND'],
            valueToDate: this.toFilterDateString(plusTwoBusinessDays)
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;

        case 'CCY Part 2 list':
          query = {
            eventStatuses: [
              { valueId: 1, valueDescription: 'FIXING DATE TO BE REACHED' },
              { valueId: 2, valueDescription: 'EVENT TO BE INPUT' },
              { valueId: 7, valueDescription: 'VALUATION IN REPAIR' }
            ],
            issuerPerimter: 'STA',
            paymentCurrencies: ['CAD', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HUF', 'MXN', 'NOK', 'PLN', 'RON', 'RUB', 'SEK', 'TRY', 'USD', 'ZAR'],
            valueToDate: this.toFilterDateString(plusTwoBusinessDays)
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;

        case 'CCY Part 1 (exotic) list ':
          query = {
            eventStatuses: [
              { valueId: 1, valueDescription: 'FIXING DATE TO BE REACHED' },
              { valueId: 2, valueDescription: 'EVENT TO BE INPUT' },
              { valueId: 8, valueDescription: 'EVENT VALIDATED' },
              { valueId: 9, valueDescription: 'EVENT TO BE VALIDATED' },
              { valueId: 10, valueDescription: 'NOTIFICATION SENT' },
              { valueId: 12, valueDescription: 'GENERATE REQUEST OF FUNDS' },
              { valueId: 13, valueDescription: 'REQUEST OF FUNDS SENT' },
              { valueId: 14, valueDescription: 'GENERATE PREADVISE' },
              { valueId: 15, valueDescription: 'PREADVISE SENT' },
              { valueId: 17, valueDescription: 'PAYMENT TO BE INPUT' },
              { valueId: 7, valueDescription: 'VALUATION IN REPAIR' }
            ],
            issuerPerimter: 'STA',
            paymentCurrencies: ['AED', 'AUD', 'BGN', 'BHD', 'CNY', 'HKD', 'HRK', 'ILS', 'INR', 'ISK', 'JPY', 'KWD', 'MAD', 'NZD', 'OMR', 'QAR', 'RSD', 'SAR', 'SGD', 'THB', 'TND'],
            valueToDate: this.toFilterDateString(new Date())
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;

        case 'CCY Part 2 list ':
          query = {
            eventStatuses: [
              { valueId: 1, valueDescription: 'FIXING DATE TO BE REACHED' },
              { valueId: 2, valueDescription: 'EVENT TO BE INPUT' },
              { valueId: 8, valueDescription: 'EVENT VALIDATED' },
              { valueId: 9, valueDescription: 'EVENT TO BE VALIDATED' },
              { valueId: 10, valueDescription: 'NOTIFICATION SENT' },
              { valueId: 12, valueDescription: 'GENERATE REQUEST OF FUNDS' },
              { valueId: 13, valueDescription: 'REQUEST OF FUNDS SENT' },
              { valueId: 14, valueDescription: 'GENERATE PREADVISE' },
              { valueId: 15, valueDescription: 'PREADVISE SENT' },
              { valueId: 17, valueDescription: 'PAYMENT TO BE INPUT' },
              { valueId: 7, valueDescription: 'VALUATION IN REPAIR' }
            ],
            issuerPerimter: 'STA',
            paymentCurrencies: ['CAD', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HUF', 'MXN', 'NOK', 'PLN', 'RON', 'RUB', 'SEK', 'TRY', 'USD', 'ZAR'],
            valueToDate: this.toFilterDateString(new Date())
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
      }
      switch (values) {
        case 'Notification - To be input':
          query = {
            eventStatuses: [
              { valueId: 2, valueDescription: 'EVENT TO BE INPUT' },
              { valueId: 7, valueDescription: 'VALUATION IN REPAIR' }
            ],
            issuerPerimter: 'SPI'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
        case 'Notification - To be validated':
          query = {
            eventStatuses: [{ valueId: 9, valueDescription: 'EVENT TO BE VALIDATED' }],
            issuerPerimter: 'SPI'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
        case 'To be paid':
          query = {
            eventStatuses: [
              { valueId: 16, valueDescription: 'PREADVISE BLOCKED' },
              { valueId: 17, valueDescription: 'PAYMENT TO BE INPUT' },
              { valueId: 19, valueDescription: 'PAYMENT IN REPAIR' },
              { valueId: 21, valueDescription: 'PAYMENT FAILED' }
            ],
            issuerPerimter: 'SPI'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
        case 'Events blocked':
          query = {
            eventStatuses: [
              { valueId: 11, valueDescription: 'NOTIFICATION BLOCKED' },
              { valueId: 16, valueDescription: 'PREADVISE BLOCKED' },
              { valueId: 18, valueDescription: 'PAYMENT BLOCKED' },
              { valueId: 28, valueDescription: 'REQUEST OF FUNDS BLOCKED' }
            ],
            issuerPerimter: 'SPI'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
        case 'VD to VD+2 - Not closed': {
          const { plusTwoBusinessDays, todayDate } = this.plusTwoBusinessDaysSearch();
          query = {
            eventStatuses: [
              { valueId: 1, valueDescription: 'FIXING DATE TO BE REACHED' },
              { valueId: 2, valueDescription: 'EVENT TO BE INPUT' },
              { valueId: 7, valueDescription: 'VALUATION IN REPAIR' },
              { valueId: 8, valueDescription: 'EVENT VALIDATED' },
              { valueId: 9, valueDescription: 'EVENT TO BE VALIDATED' },
              { valueId: 10, valueDescription: 'NOTIFICATION SENT' },
              { valueId: 11, valueDescription: 'NOTIFICATION BLOCKED' },
              { valueId: 13, valueDescription: 'REQUEST OF FUNDS SENT' },
              { valueId: 15, valueDescription: 'PREADVISE SENT' },
              { valueId: 16, valueDescription: 'PREADVISE BLOCKED' },
              { valueId: 18, valueDescription: 'PAYMENT BLOCKED' },
              { valueId: 19, valueDescription: 'PAYMENT IN REPAIR' },
              { valueId: 21, valueDescription: 'PAYMENT FAILED' },
              { valueId: 28, valueDescription: 'REQUEST OF FUNDS BLOCKED' }
            ],
            issuerPerimter: 'SPI',
            valueFromDate: this.toFilterDateString(todayDate),
            valueToDate: this.toFilterDateString(plusTwoBusinessDays)
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
        }
        case 'Total events': {
          const { yesterdayDate } = this.oneMonthMinusYesterdaysDateSearch();
          query = {
            eventStatuses: [
              { valueId: 1, valueDescription: 'FIXING DATE TO BE REACHED' },
              { valueId: 2, valueDescription: 'EVENT TO BE INPUT' },
              { valueId: 7, valueDescription: 'VALUATION IN REPAIR' },
              { valueId: 30, valueDescription: 'NOTIFICATION IN REPAIR' },
              { valueId: 29, valueDescription: 'REQUEST OF FUNDS IN REPAIR' },
              { valueId: 4, valueDescription: 'PREADVISE IN REPAIR' }
            ],
            issuerPerimter: 'STA',
            notificationToDate: this.toFilterDateString(yesterdayDate)
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
        }

        case 'VD-2 control exotic currency': {
          const { plusTwoBusinessDays } = this.plusTwoBusinessDaysSearch();
          query = {
            eventStatuses: [
              { valueId: 1, valueDescription: 'FIXING DATE TO BE REACHED' },
              { valueId: 2, valueDescription: 'EVENT TO BE INPUT' },
              { valueId: 7, valueDescription: 'VALUATION IN REPAIR' }
            ],
            issuerPerimter: 'STA',
            valueToDate: this.toFilterDateString(plusTwoBusinessDays)
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
        }

        case 'Event to be validated':
          query = {
            eventStatuses: [{ valueId: 9, valueDescription: 'EVENT TO BE VALIDATED' }],
            issuerPerimter: 'STA'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;

        case 'Valuation in repair':
          query = {
            eventStatuses: [{ valueId: 7, valueDescription: 'VALUATION IN REPAIR' }],
            issuerPerimter: 'STA'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;

        case 'Events not reconciled':
          valuationQuery = {
            valuationStatus: [{ valueId: 3, valueDescription: 'NOT RECONCILED' }],
            valuationRejectReasons: [...VALUATION_DASHBOARD_QUERY_REJECT_REASONS],
            receiptFromDate: this.toFilterDateString(threeMonthMinusTodayDate),
            receiptToDate: this.toFilterDateString(todayDate)
          };
          filterParams = JSON.stringify(valuationQuery);
          this.router.navigate(routeToValuation(), { queryParams: { query: filterParams } });
          break;

        case 'Valuation cancel':
          valuationQuery = {
            valuationVersion: 'CANCEL',
            receiptFromDate: this.toFilterDateString(threeMonthMinusTodayDate),
            receiptToDate: this.toFilterDateString(todayDate)
          };
          filterParams = JSON.stringify(valuationQuery);
          this.router.navigate(routeToValuation(), { queryParams: { query: filterParams } });
          break;

        case 'Valuation update':
          valuationQuery = {
            valuationVersion: 'UPDATE',
            receiptFromDate: this.toFilterDateString(threeMonthMinusTodayDate),
            receiptToDate: this.toFilterDateString(todayDate)
          };
          filterParams = JSON.stringify(valuationQuery);
          this.router.navigate(routeToValuation(), { queryParams: { query: filterParams } });
          break;

        case 'Await new valuation':
          valuationQuery = {
            valuationStatus: [{ valueId: 4, valueDescription: 'AWAIT NEW VALUATION' }]
          };
          filterParams = JSON.stringify(valuationQuery);
          this.router.navigate(routeToValuation(), { queryParams: { query: filterParams } });
          break;

        case 'Events in repair /failed':
          query = {
            eventStatuses: [
              { valueId: 21, valueDescription: 'PAYMENT FAILED' },
              { valueId: 19, valueDescription: 'PAYMENT IN REPAIR' },
              { valueId: 30, valueDescription: 'NOTIFICATION IN REPAIR' },
              { valueId: 29, valueDescription: 'REQUEST OF FUNDS IN REPAIR' },
              { valueId: 4, valueDescription: 'PREADVISE IN REPAIR' }
            ],
            issuerPerimter: 'STA'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;

        case 'Await new payment':
          paymentsQuery = {
            paymentStatus: [{ valueId: '7', valueDescription: 'AWAIT NEW PAYMENT' }]
          };
          filterParams = JSON.stringify(paymentsQuery);
          this.router.navigate(routeToPayment(), { queryParams: { query: filterParams } });
          break;

        case 'Markdown pending':
          query = {
            eventStatuses: [{ valueId: 23, valueDescription: 'MARKDOWN PENDING' }],
            issuerPerimter: 'STA'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;

        case 'Alert - Events blocked':
          query = {
            eventStatuses: [
              { valueId: 11, valueDescription: 'NOTIFICATION BLOCKED' },
              { valueId: 16, valueDescription: 'PREADVISE BLOCKED' },
              { valueId: 18, valueDescription: 'PAYMENT BLOCKED' },
              { valueId: 28, valueDescription: 'REQUEST OF FUNDS BLOCKED' }
            ],
            issuerPerimter: 'STA'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;

        case 'Partial payments':
          query = {
            eventStatuses: [{ valueId: 27, valueDescription: 'PAYMENT PARTIALLY PAID' }],
            issuerPerimter: 'STA'
          };
          filterParams = JSON.stringify(query);
          this.router.navigate(routeToEvent(), { queryParams: { query: filterParams } });
          break;
      }
    }
  }

  private readonly toFilterDateString = (date: Date): string => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  };

  private readonly toDateString = (date: Date): string => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  };

  private oneMonthMinusYesterdaysDateSearch() {
    const todayDate = new Date(); // Gives today's date
    const yesterdayDate = new Date(); // Gives today's date
    const oneMonthMinusYesterdayDate = new Date();
    const todaysDayOfMonth = todayDate.getDate(); // Gives day of the month
    yesterdayDate.setDate(todaysDayOfMonth - 1); // Gives yesterdays date
    oneMonthMinusYesterdayDate.setMonth(yesterdayDate.getMonth() - 1); // Gives previous months date
    return { oneMonthMinusYesterdayDate, yesterdayDate };
  }

  private threeMonthMinusTodaysDateSearch() {
    const todayDate = new Date(); // Gives today's date
    const threeMonthMinusTodayDate = new Date();
    threeMonthMinusTodayDate.setMonth(todayDate.getMonth() - 3); // Gives previous months date
    return { threeMonthMinusTodayDate, todayDate };
  }

  private plusTwoBusinessDaysSearch() {
    const todayDate = new Date(); // Gives today's date
    const plusTwoBusinessDays = new Date(); // Gives today's date
    const todaysDayOfMonth = todayDate.getDate(); // Gives day of the month
    plusTwoBusinessDays.setDate(todaysDayOfMonth + 2); // Gives plus two days date
    if (this.isFriday(todayDate)) {
      plusTwoBusinessDays.setDate(plusTwoBusinessDays.getDate() + 2); // Gives plus two days date, if todayDate is friday to tuesday
      if (this.isHoliday(plusTwoBusinessDays)) {
        this.getNextDate(plusTwoBusinessDays);
      }
    } else if (this.isHoliday(plusTwoBusinessDays)) {
      this.getNextDate(plusTwoBusinessDays);
    }
    return { plusTwoBusinessDays, todayDate };
  }

  private getNextDate(plusTwoBusinessDays: Date) {
    const nextDayOfMonth = plusTwoBusinessDays.getDate();
    plusTwoBusinessDays.setDate(nextDayOfMonth + 1);
    if (this.isHoliday(plusTwoBusinessDays)) {
      this.getNextDate(plusTwoBusinessDays);
    }
  }

  private isHoliday(getDate: Date) {
    const month = getDate.getMonth();
    const date = getDate.getDate();
    const day = getDate.getDay();
    return day === 6 || day === 0 || (month === 1 && date === 1) || (month === 4 && date === 1) || (month === 11 && date === 25);
  }

  private isFriday(getDate: Date) {
    const day = getDate.getDay();
    return day === 5;
  }
}
