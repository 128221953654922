import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionShellComponent } from './instruction-shell/instruction-shell.component';
import { instructionRoutes as InstructionRoutes } from './instructions.routing';
import { SharedModule } from '@shared/shared.module';
import { HeaderModule } from '@shared/modules/header/header.module';
import { TranslateModule } from '@ngx-translate/core';
import { InstructionsContainerComponent } from './instructions-container/instructions-container.component';
import { InstructionsListComponent } from './instructions-list/instructions-list.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  declarations: [InstructionShellComponent, InstructionsContainerComponent, InstructionsListComponent],
  imports: [CommonModule, InstructionRoutes, SharedModule.forRoot(), HeaderModule, TranslateModule, AgGridModule]
})
export class InstructionsModule {
  static forRoot(): ModuleWithProviders<InstructionsModule> {
    return {
      ngModule: InstructionsModule
    };
  }
}
