import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PositionsTotalGridModel, PositionsGridModel, EventPositionInputModel, UpdatePositionModel, UpdateAdjustedQuantityModel } from '@events/models';
import { ColDef, GridOptions, GridApi } from 'ag-grid-community';
import { PositionsColumnDefService } from '../event-detail/_aggrid/positions-column-def-service';
import { PositionsTotalColumnDefService } from '../event-detail/_aggrid/positions-total-columns-def.service';
import { TYPED_DATA_TYPE } from '@shared/models';
import { EventsPositionService } from '@shared/services/events-position.service';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';
import { EventsService } from '@shared/services/events.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-positions-list',
  templateUrl: './positions-list.component.html',
  providers: [PositionsColumnDefService, PositionsTotalColumnDefService]
})
export class PositionsListComponent implements OnInit {
  @Input() public positions: PositionsGridModel[] = [];
  @Input() public positionsTotal: PositionsTotalGridModel[] = [];
  @Input() public eventId: number | undefined;
  @Input() public eventPositionInput: EventPositionInputModel | undefined;
  @Input() public hideActionsColumn: boolean | undefined;
  @Input() public hideTotalsGrid: boolean | undefined;
  @Output() public positionUpdated = new EventEmitter<void>();

  public INTERVENTION_TYPE: TYPED_DATA_TYPE = 'INTERVENTION_TYPE';
  public positionsColumnDefs: ColDef[] = [];
  public positionsTotalColumnDefs: ColDef[] = [];
  public defaultColDef: any;
  public gridOptions!: GridOptions;
  public totalGridOptions!: GridOptions;
  public gridApi!: GridApi;
  public gridTotalApi!: GridApi;
  isExchCertEvent = false;
  private readonly updatePositionTitle = 'toasts.events.updatePosition.title';
  private readonly updatePositionMessage = 'toasts.events.updatePosition.message';
  private readonly positionUpdateFailureTitle = 'toasts.events.positionUpdateFailure.title';
  private readonly positionUpdateFailureMessage = 'toasts.events.positionUpdateFailure.message';

  constructor(
    readonly positionsColumnDefService: PositionsColumnDefService,
    readonly positionsTotalColumnDefService: PositionsTotalColumnDefService,
    private readonly eventsPositionService: EventsPositionService,
    private readonly eventService: EventsService,
    private readonly toastManagerService: ToastManagerService
  ) {}

  ngOnInit(): void {
    this.positionsColumnDefs = this.positionsColumnDefService.get();
    this.positionsTotalColumnDefs = this.positionsTotalColumnDefService.get();
    if (this.eventPositionInput?.downloadRof) {
      this.positionsTotalColumnDefs[8].cellRendererParams.eventId = this.eventId;
      this.positionsTotalColumnDefs[8].cellRendererParams.eventType = this.eventPositionInput?.eventTypeValue;
      this.positionsTotalColumnDefs[8].cellRendererParams.isin = this.positionsTotal[0].isin;
      this.positionsTotalColumnDefs[8].cellRendererParams.sgDocId = this.eventPositionInput?.downloadRof;
    } else {
      this.positionsTotalColumnDefs[8].cellRendererParams.eventType = null;
      this.positionsTotalColumnDefs[8].cellRendererParams.isin = null;
      this.positionsTotalColumnDefs[8].cellRendererParams.sgDocId = null;
    }

    this.positionsColumnDefs[8].cellRendererParams.eventId = this.eventPositionInput?.enableReleasePayment ? this.eventId : null;

    if (this.eventPositionInput?.enableGenerateRequestOfFunds) {
      this.positionsTotalColumnDefs[8].cellRendererParams.eventId = this.eventId;
      this.positionsTotalColumnDefs[8].cellRendererParams.generateReqOfFunds = true;
    } else {
      this.positionsTotalColumnDefs[8].cellRendererParams.generateReqOfFunds = null;
    }

    if (this.eventPositionInput?.eventTypeValue === EVENT_CONSTANTS.eventTypes.EXCH) {
      this.totalHeaderExchCert();
      this.positionsColumnDefs[2].field = 'blockedQuantity';
      this.positionsColumnDefs[3].hide = true;
      this.positionsColumnDefs[4].field = 'quantityToUnblock';
      this.positionsColumnDefs[5].field = 'semeReference';
      this.positionsColumnDefs[6].hide = true;
      this.isExchCertEvent = true;
    } else if (this.eventPositionInput?.eventTypeValue === EVENT_CONSTANTS.eventTypes.CERT) {
      this.totalHeaderExchCert();
      this.positionsColumnDefs[2].field = 'blockedQuantity';
      this.positionsColumnDefs[3].hide = true;
      this.positionsColumnDefs[4].field = 'positionToCertify';
      this.positionsColumnDefs[5].field = 'semeReference';
      this.positionsColumnDefs[6].hide = true;
      this.isExchCertEvent = true;
    } else if (this.eventPositionInput?.tradingType === EVENT_CONSTANTS.tradingMethod.NOMINAL) {
      this.positionsColumnDefs[1].field = 'positionNominal';
      this.positionsColumnDefs[2].field = 'quantity';
      this.positionsColumnDefs[4].field = 'nonEligibleNominal';
      this.positionsColumnDefs[5].field = 'eligibleNominal';
      this.positionsColumnDefs[6].hide = false;
      this.positionsTotalColumnDefs[1].field = 'totalPositionNominal';
      this.positionsTotalColumnDefs[4].field = 'totalNonEligibleNominal';
      this.positionsTotalColumnDefs[5].field = 'totalEligibleNominal';
    } else if (this.eventPositionInput?.tradingType === EVENT_CONSTANTS.tradingMethod.UNIT) {
      this.positionsColumnDefs[1].field = 'positionUnits';
      this.positionsColumnDefs[2].field = 'quantity';
      this.positionsColumnDefs[4].field = 'nonEligibleUnits';
      this.positionsColumnDefs[5].field = 'eligibleUnits';
      this.positionsColumnDefs[6].hide = false;
      this.positionsTotalColumnDefs[1].field = 'totalPositionUnits';
      this.positionsTotalColumnDefs[4].field = 'totalNonEligibleUnits';
      this.positionsTotalColumnDefs[5].field = 'totalEligibleUnits';
    }

    if (this.positions.length > 0 && this.eventPositionInput?.isRedemptionInShares) {
      this.positionsColumnDefs[7].hide = false;
      this.positionsTotalColumnDefs[7].hide = false;
    } else {
      this.positionsColumnDefs[7].hide = true;
      this.positionsTotalColumnDefs[7].hide = true;
    }

    this.gridOptions = {
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      pagination: false,
      suppressPaginationPanel: false,
      suppressContextMenu: true,
      enableCellTextSelection: true,
      stopEditingWhenCellsLoseFocus: true
    };

    this.totalGridOptions = {
      onGridSizeChanged: () => {
        this.totalGridOptions.api?.sizeColumnsToFit();
      },
      pagination: false,
      suppressPaginationPanel: false,
      suppressContextMenu: true,
      enableCellTextSelection: true
    };

    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true,
      enableFilter: false,
      enableGroupEdit: false,
      enableSorting: false,
      floatingFilter: false
    };
    if (this.hideActionsColumn) {
      this.positionsColumnDefs[8].hide = true;
      this.positionsTotalColumnDefs[8].hide = true;
    } else {
      this.positionsColumnDefs[8].hide = false;
      this.positionsTotalColumnDefs[8].hide = false;
    }
  }

  private totalHeaderExchCert() {
    if (this.positionsTotal[0].tradingMethod === EVENT_CONSTANTS.tradingMethod.NOMINAL) {
      this.positionsColumnDefs[1].field = 'totalNominal';
    } else if (this.positionsTotal[0].tradingMethod === EVENT_CONSTANTS.tradingMethod.UNIT) {
      this.positionsColumnDefs[1].field = 'totalUnits';
    }
  }

  onCellValueChanged(event: any) {
    event.data.modified = true;
  }

  onCellValueTotalChanged(event: any) {
    event.data.modified = true;
  }

  onGridReady(params: any) {
    const gridApi = params.api;
    gridApi.closeToolPanel(true);
    gridApi.refreshHeader();
    gridApi.sizeColumnsToFit();
    this.gridApi = params.api;
  }

  onTotalGridReady(params: any) {
    const gridApi = params.api;
    gridApi.closeToolPanel(true);
    gridApi.refreshHeader();
    gridApi.sizeColumnsToFit();
    this.gridTotalApi = params.api;
  }

  public saveModifiedRows(doPositionAmountRecalculation: boolean) {
    const positionData: any = [];
    const positionTotalData: any = [];

    this.gridApi?.forEachNode(node => positionData.push(node.data));
    this.gridTotalApi?.forEachNode(node => positionTotalData.push(node.data));
    if (
      positionData[0]?.eventType &&
      (positionData[0].eventType === EVENT_CONSTANTS.eventTypes.EXCH || positionData[0].eventType === EVENT_CONSTANTS.eventTypes.CERT) &&
      (Number(positionData[0].quantityToUnblock) > positionData[0].blockedQuantity || Number(positionData[1].quantityToUnblock) > positionData[1].blockedQuantity)
    ) {
      this.toastManagerService.toastError(this.positionUpdateFailureTitle, this.positionUpdateFailureMessage);
      return;
    }

    const modifiedRows = positionData.filter((row: { [x: string]: any }) => {
      return row['modified'];
    });
    const modifiedTotalRows = positionTotalData.filter((row: { [x: string]: any }) => {
      return row['modified'];
    });

    if (modifiedRows.length > 0) {
      const updateAdjQuantity: UpdateAdjustedQuantityModel = {
        eventId: this.eventId?.toString(),
        clsAdjQty: Number(positionData[0].adjustedQuantity),
        eocAdjQty: Number(positionData[1].adjustedQuantity),
        updateAdjustedQty: true
      };
      const updatePosition: UpdatePositionModel = {
        // commented as positions quatity is no more modified, will remove if no regression caused after testing.
        // clsPosition: Number(positionData[0].quantity),
        // eocPosition: Number(positionData[1].quantity),
        eventId: this.eventId,
        pullFromCommonDepository: false
      };
      if (this.eventPositionInput?.eventTypeValue === EVENT_CONSTANTS.eventTypes.EXCH) {
        updatePosition.clsPosition = Number(positionData[0].quantityToUnblock);
        updatePosition.eocPosition = Number(positionData[1].quantityToUnblock);
        updatePosition.clsSemeReference = positionData[0].semeReference;
        updatePosition.eocSemeReference = positionData[1].semeReference;
        updatePosition.pullFromCommonDepository = false;
      } else if (this.eventPositionInput?.eventTypeValue === EVENT_CONSTANTS.eventTypes.CERT) {
        updatePosition.clsPosition = Number(positionData[0].positionToCertify);
        updatePosition.eocPosition = Number(positionData[1].positionToCertify);
        updatePosition.clsSemeReference = positionData[0].semeReference;
        updatePosition.eocSemeReference = positionData[1].semeReference;
        updatePosition.pullFromCommonDepository = false;
        // commented as positions quatity is no more modified, will remove if no regression caused after testing.
        // } else if (this.eventPositionInput?.eventTypeValue === EVENT_CONSTANTS.eventTypes.REDM && this.eventPositionInput?.isInternationalISIN) {
        //   updatePosition.pullFromCommonDepository = false;
      }

      if (positionData[0]?.eventType === EVENT_CONSTANTS.eventTypes.EXCH || positionData[0]?.eventType === EVENT_CONSTANTS.eventTypes.CERT) {
        this.eventsPositionService.updatePosition(updatePosition).subscribe(result => {
          this.toastManagerService.toastSuccess(this.updatePositionTitle, this.updatePositionMessage, undefined, result);
          this.positionUpdated.next();
        });
      } else {
        this.eventService.updateAdjQty(updateAdjQuantity).subscribe(res => {
          if (res) {
            this.toastManagerService.toastSuccess('toasts.events.adjustedQuantityUpdated.title', 'toasts.events.adjustedQuantityUpdated.message', undefined, res);
          } else {
            this.toastManagerService.toastWarning('toasts.events.adjustedQuantityUpdateFailure.title', 'toasts.events.adjustedQuantityUpdateFailure.message', undefined, res);
          }
          this.positionUpdated.next();
        });
        catchError(error => of(error));
      }
    } else if (modifiedTotalRows.length > 0) {
      const updateTotalAdjQuantity: UpdateAdjustedQuantityModel = {
        eventId: this.eventPositionInput?.eventId?.toString(),
        totalAdjQty: Number(positionTotalData[0].totalAdjustedQuantity),
        updateAdjustedQty: true
      };
      this.eventService.updateAdjQty(updateTotalAdjQuantity).subscribe(res => {
        if (res) {
          this.toastManagerService.toastSuccess('toasts.events.adjustedQuantityUpdated.title', 'toasts.events.adjustedQuantityUpdated.message', undefined, res);
        } else {
          this.toastManagerService.toastWarning('toasts.events.adjustedQuantityUpdateFailure.title', 'toasts.events.adjustedQuantityUpdateFailure.message', undefined, res);
        }
        this.positionUpdated.next();
      });
      catchError(error => of(error));

      // code commented to be removed if no regression caused after testing.
      // this.eventsPositionService.updatePosition(updatePosition).subscribe(result => {
      //   this.toastManagerService.toastSuccess(this.updatePositionTitle, this.updatePositionMessage, undefined, result);
      //   this.positionUpdated.next();
      // });
    } else if (doPositionAmountRecalculation) {
      const updatePosition: UpdatePositionModel = {
        eventId: this.eventId,
        doPositionAmountRecalculation: doPositionAmountRecalculation,
        pullFromCommonDepository: false
      };

      this.eventsPositionService.updatePosition(updatePosition).subscribe(result => {
        this.toastManagerService.toastSuccess(this.updatePositionTitle, this.updatePositionMessage, undefined, result);
        this.positionUpdated.next();
      });
      // code commented to be removed if no regression caused after testing
      // } else if (!this.eventPositionInput?.isQtyUpdated) {
      //   const updatePosition: CreatePositionModel = {
      //     eventId: this.eventId ?? -1,
      //     pullFromCommonDepository: this.eventPositionInput?.isInternationalISIN
      //   };
      //   this.eventsPositionService.createPosition(updatePosition).subscribe(result => {
      //     this.toastManagerService.toastSuccess(this.updatePositionTitle, this.updatePositionMessage, undefined, result);
      //     this.positionUpdated.next();
      //   });
    } else {
      this.positionUpdated.next();
    }
  }
}
