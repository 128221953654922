import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InstructionModel, toInstructionModel } from '../model/instruction.model';
import { PartyResourceService } from '@apis/backend/instruments';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class InstructionService {
  constructor(private readonly partyResourceService: PartyResourceService) {}
  get(): Observable<InstructionModel[]> {
    return this.partyResourceService.getAllExtCashAccount().pipe(map(dto => dto.resources?.map(el => toInstructionModel(el)) ?? []));
  }
}
