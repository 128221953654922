import { Component, ViewChild } from '@angular/core';
import { ValuationCriteriaSearchFormService, ValuationSearchService, ValuationSearchQueryModel } from '@valuation/services';
import { ValuationService } from '@shared/services/valuation.service';
import { ValuationCriteriaSearchModel, toValuationSearchInputModel, toValuationModel, ValuationModel, toValuationCsvExportModel } from '@valuation/models';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { ValuationListComponent } from '@valuation/components';
import { VALUATION_CONSTANTS } from '@valuation/configs/valuation-constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { downloadFile } from '@utils/utility-functions';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-valuation-container',
  templateUrl: './valuation-container.component.html',
  styleUrls: ['./valuation-container.component.scss'],
  providers: [ValuationCriteriaSearchFormService, ValuationSearchService]
})
export class ValuationContainerComponent {
  @ViewChild(ValuationListComponent) valuationListComponent!: ValuationListComponent;
  private readonly shutdown$ = new Subject<void>();
  private searchCriteria: ValuationCriteriaSearchModel | null | undefined;
  public valuations: ValuationModel[] = [];
  public disableSearch = false;
  public displayPagination = false;
  loaded?: Promise<boolean>;
  itemsByPage = 50;
  pageNumber = 1;
  totalItems = 0;

  constructor(
    private readonly valuationService: ValuationService,
    private readonly valuationCriteriaSearchFormService: ValuationCriteriaSearchFormService,
    private readonly valuationSearchService: ValuationSearchService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toastManagerService: ToastManagerService
  ) {}

  ngOnInit() {
    const queryString = this.activatedRoute.snapshot.queryParams.query;
    if (queryString) {
      const query = JSON.parse(queryString) as ValuationSearchQueryModel;
      this.valuationSearchService.setInitialValue(query);
    }
    this.subscribeToFormChanges();
    if (queryString !== undefined && queryString !== '{}') {
      this.search();
    }
  }

  search(): void {
    this.pageNumber = 1;
    const userInput = this.valuationCriteriaSearchFormService.rawValue();
    if (userInput.eventRef) {
      this.loadValuations();
    } else if (userInput.valuationVersion) {
      if (userInput.receiptFromDate && userInput.receiptToDate) {
        this.loadValuations();
      } else {
        this.toastManagerService.toastError('toasts.valuation.mandatoryValuationVersionSearchCriteria.title', 'toasts.valuation.mandatoryValuationVersionSearchCriteria.message');
      }
    } else if (userInput.isin || userInput.valuationRef || (userInput.valuationStatus && userInput.valuationStatus?.length > 0)) {
      if (userInput.valuationStatus) {
        const valuationStatuses = userInput.valuationStatus.map(x => x.valueDescription).every(x => VALUATION_CONSTANTS.valuationStatusSearchCondtion.includes(x!));
        if (!valuationStatuses && !((userInput.receiptFromDate && userInput.receiptToDate) || (userInput.valueFromDate && userInput.valueToDate))) {
          this.toastManagerService.toastError('toasts.valuation.mandatoryDateSearchCriteria.title', 'toasts.valuation.mandatoryDateSearchCriteria.message');
          return;
        }
      }
      this.loadValuations();
    } else {
      this.toastManagerService.toastError('toasts.valuation.searchCriteria.title', 'toasts.valuation.searchCriteria.message');
    }
  }

  private subscribeToFormChanges(): void {
    const Allkeys = this.valuationSearchService.getfields();
    this.valuationSearchService.valueChanges(Allkeys).subscribe(q => {
      const query = this.removeEmpty(q);
      const stringfiedPredicate = JSON.stringify(query);
      this.router.navigate(['/valuation'], { queryParams: { query: stringfiedPredicate } });
    });
  }

  loadValuations() {
    this.disableSearch = true;
    this.valuations = [];
    this.searchCriteria = this.valuationCriteriaSearchFormService.value();
    const valuationSearchInputModel = this.searchCriteria ? toValuationSearchInputModel(this.searchCriteria, this.pageNumber, this.itemsByPage) : null;
    this.valuationService.getValuations(valuationSearchInputModel ?? {}).subscribe(
      response => {
        if (response?.dtos) {
          response?.dtos?.forEach(valuation => {
            const valuationData = toValuationModel(valuation);
            if (valuationData) {
              this.valuations.push(valuationData);
            }
            this.totalItems = response.totalItems!;
            this.displayPagination = true;
            this.disableSearch = false;
            this.loaded = Promise.resolve(true);
          });
        } else {
          this.toastManagerService.toastError('toasts.valuation.notFound.title', 'toasts.valuation.notFound.message', undefined, response);
          this.valuations = [];
          this.disableSearch = false;
          this.loaded = Promise.resolve(true);
        }
      },
      error => {
        this.disableSearch = false;
        if (error.status === 404) {
          this.valuations = [];
          this.loaded = Promise.resolve(true);
        }
      }
    );
  }

  onPageChanged(selectedPageNumber: any) {
    this.pageNumber = selectedPageNumber;
    this.loadValuations();
  }

  public exportCsv() {
    this.disableSearch = true;
    const valuationSearchInputModel = this.searchCriteria ? toValuationCsvExportModel(this.searchCriteria) : null;
    this.valuationService
      .exportValuationsData(valuationSearchInputModel!)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        blob => {
          if (!blob) {
            return;
          }
          const date = formatDate(new Date(), 'yyyy_MM_dd', 'en');
          downloadFile(blob, `${date}_valuation` + '.csv');
          this.disableSearch = false;
        },
        error => {
          if (error) this.disableSearch = false;
        }
      );
  }

  removeEmpty(obj: any) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
}
