<div class="row mt-3">
  <div class="col col-md-6">
      <div class="d-flex flex-column">
        <div class="align-self-center h3">SPI Events Indicators</div>
  <ag-grid-angular 
    style="width: 100%; height: 300px;"
    class="ag-theme-alpine"
    class="ag-theme-sg-bootstrap-condensed"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [groupDisplayType]="groupDisplayType"
    [animateRows]="true"
    [rowData]="spiRowData"
    (selectionChanged)="onSelectionChanged($event)"
    rowSelection="single"
    [gridOptions]="gridOptions"
    (gridReady)="onSPIGridReady($event)"
  ></ag-grid-angular>
</div>
</div>
  <div class="col col-md-6">
    <div class="d-flex flex-column">
      <div class="align-self-center h3">STA Events Indicators</div>
  <ag-grid-angular 
    style="width: 100%; height: 300px;"
    class="ag-theme-alpine"
    class="ag-theme-sg-bootstrap-condensed"
    [columnDefs]="staColumnDefs"
    [defaultColDef]="defaultColDef"
    [groupDisplayType]="groupDisplayType"
    [animateRows]="true"
    [rowData]="staRowData"
    (selectionChanged)="onSelectionChanged($event)"
    rowSelection="single"
    [gridOptions]="gridOptions"
    (gridReady)="onSPIGridReady($event)"
  ></ag-grid-angular>
</div>
</div>
</div>

