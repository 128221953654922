export const PIWIK_CONST = {
  config: {
    trakcerUrl: 'piwik.php',
    scriptFileName: 'piwik.js'
  },
  methods: {
    trackEvent: 'trackEvent',
    trackPageView: 'trackPageView'
  }
};
