/* eslint-disable @typescript-eslint/no-explicit-any */
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EnvServiceProvider, SgsPermissionService } from 'sgs-angular-components';
import { SgConnectService } from 'src/app/core/sg-connect/sg-connect.service';
import { sgwtConnectFactory, sgwtPredicate } from 'src/app/sgwtConnect';
import { environment } from 'src/environments/environment';

import { CommonModule, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { ApiModule, BASE_PATH } from '@apis/backend/instruments';
import { ApiModule as EventsApiModule, BASE_PATH as EVENTS_BASE_PATH } from '@apis/backend/events';
import { ApiModule as PaymentsApiModule, BASE_PATH as PAYMENTS_BASE_PATH } from '@apis/backend/payments';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SGWTConnectModule } from '@sgwt/connect-angular';

import { AppComponent } from './app.component';
import { ROUTES } from './app.routing';
import { InstrumentModule } from './instruments/instrument.module';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { ToastModule } from './shared/modules/toasts/toast.module';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from '@home/home.module';
import { ProgrammeModule } from '@programmes/programme.module';
import { EventsModule } from '@events/events.module';
import { ValuationModule } from './valuation/valuation.module';
import { PositionsKeepingModule } from './positions-keeping/positions-keeping.module';
import { PaymentModule } from './payment/payment.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InstructionsModule } from './instructions/instructions.module';
import { EcashPaymentsModule } from './eCashPayments/eCash-payments.module';

// eslint-disable-next-line @typescript-eslint/naming-convention
export declare let __webpack_public_path__: string;

registerLocaleData(localeFr, 'fr');

// AoT requires an exported function for factories
const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, __webpack_public_path__.replace(/\/$/, '') + './assets/i18n/');

// Export sgwtConnect factory (AOT requirement):

// Export sgwtConnect predicate (AOT requirement):

@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    HomeModule.forRoot(),
    ProgrammeModule.forRoot(),
    InstrumentModule.forRoot(),
    InstructionsModule.forRoot(),
    PositionsKeepingModule.forRoot(),
    EventsModule.forRoot(),
    ValuationModule,
    PaymentModule,
    EcashPaymentsModule,
    NgbModule,
    BrowserModule,
    ApiModule,
    EventsApiModule,
    PaymentsApiModule,
    RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: environment.sgsEnvProperties.csrfCookieName,
      headerName: environment.sgsEnvProperties.csrfHeaderName
    }),
    SGWTConnectModule.forRoot(sgwtConnectFactory, sgwtPredicate),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule.forRoot(),
    PdfViewerModule,
    MatTableModule,
    CommonModule,
    ToastModule,
    BrowserAnimationsModule
  ],
  providers: [
    EnvServiceProvider,
    SgsPermissionService,
    SgConnectService,
    {
      provide: BASE_PATH,
      useValue: `${environment.sgsEnvProperties.backend_api_protocol}://${environment.sgsEnvProperties.backend_api_host}`
    },
    {
      provide: EVENTS_BASE_PATH,
      useValue: `${environment.sgsEnvProperties.backend_api_protocol}://${environment.sgsEnvProperties.event_backend_api_host}`
    },
    {
      provide: PAYMENTS_BASE_PATH,
      useValue: `${environment.sgsEnvProperties.backend_api_protocol}://${environment.sgsEnvProperties.payment_backend_api_host}`
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  /* TODO         https://developer.sgmarkets.com/docs/web/how-to/guide-tour.html     https://shepherdjs.dev/
                    https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap-v4/tree/develop/%40sg-bootstrap/shepherd

    const startTour = () => {
        // retrieve the tour, depending on the framework you use. An example with React:
        // const tour = useContext(ShepherdTourContext);
        tour.start();
    }

    const helpCenter = document.querySelector('sgwt-help-center');
    helpCenter.addEventListener('sgwt-help-center--start-introduction-tour', startTour);

    // Remember to remove this event listener, when the component is unmounted for example:
    // helpCenter.removeEventListener('sgwt-help-center--start-introduction-tour', startTour); */
}
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return () => {
    return new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet = 'en';
        translate.setDefaultLang('en');
        translate.use(langToSet).subscribe(
          () => {
            // eslint-disable-next-line no-console
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          _ => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
  };
}
