import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ValuationService } from '@shared/services/valuation.service';
import { EventsService } from '@shared/services/events.service';
import { ValuationDetailModel } from './models';
import { EventDetailModel } from '@events/models';

@Injectable({
  providedIn: 'root'
})
export class ValuationResolve implements Resolve<[ValuationDetailModel | null, EventDetailModel | null]> {
  constructor(private readonly service: ValuationService, private readonly eventService: EventsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[ValuationDetailModel | null, EventDetailModel | null]> {
    const id = route.params['id'];
    return this.service.getValuationDetail(Number(id), true).pipe(
      mergeMap(valuation => {
        const eventId = valuation?.eventId || null;
        if (valuation?.eventId) {
          return this.eventService.getCompleteEventDetails(Number(eventId)).pipe(
            map(event => [valuation, event] as [ValuationDetailModel | null, EventDetailModel | null]),
            catchError(() => [of(null), valuation] as [any, ValuationDetailModel])
          );
        }
        return [valuation, of(null)] as [ValuationDetailModel, any];
      })
    );
  }
}
