import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, throwError } from 'rxjs';
import { tap, catchError, mergeMap, map } from 'rxjs/operators';
import {
  ScheduleEventResponse,
  EventDetailsResponse,
  SecheduleSearchInput,
  ScheduleEventDetailsResponse,
  EventDetailsSearchInput,
  RequestOfFundsResponseDto,
  SwiftMessageResponseDto,
  EcashPaymentDetailsResponse,
  PoolFactorHistoryResponseListDto,
  DashboardResponseDto,
  UpdateScheduleEvent,
  EventDetails,
  ScheduleEventPagingResultDto
} from '@apis/backend/events/model/models';
import { ScheduleEventResourceService, EventDetailsResourceService, RofResourceService, SwiftResourceService, EventEcashPaymentResourceService } from '@apis/backend/events';
import { toScheduleSearchInputDto, EventModel, toCreateEventDto, toCreateEventDetails, toInstrumentDataModel, toPoolFactorSearchDto, toEcashPaymentsSearchDto, CreatePositionModel, toScheduleSearchPageInputDto } from '@events/models';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { ErrorListFromBackendService } from '@shared/modules/error-list-from-backend/service/error-list-from-backend.service';
import { toEventDetailsSearchDto, EventDetailModel, toUpdateScheduleEventDto, toUpdateEventDto, toEventCancelUpdateDto, setEventDetails, toStausUpdateUserInput, toRofSearchDto, toReleasePaymentUpdateDto } from '@events/models/event-detail.model';
import { InstrumentService } from './instrument.service';
import { RegenerateSchedulesModel, toRegenerateSchedules } from '@events/models/regenerateSchedules.model';
import { AuditResourceService, EventsPositionResourceService } from '@apis/backend/payments';
import { EventsPositionService } from './events-position.service';
import { DocumentVersionResourceService } from '@apis/backend/instruments';
import { toSwiftSearchDto } from '@events/models/swiftsGrid.model';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private readonly scheduleEventResourceService: ScheduleEventResourceService,
    private readonly eventsPositionResourceService: EventsPositionResourceService,
    private readonly eventDetailsResourceService: EventDetailsResourceService,
    private readonly instrumentResourceService: InstrumentService,
    private readonly auditResourceService: AuditResourceService,
    private readonly eventsPositionService: EventsPositionService,
    private readonly rofResourceService: RofResourceService,
    private readonly documentVersionResourceService: DocumentVersionResourceService,
    private readonly swiftResourceService: SwiftResourceService,
    private readonly eventEcashPaymentResourceService: EventEcashPaymentResourceService,
    private readonly toastManagerService: ToastManagerService,
    private readonly errorListFromBackendService: ErrorListFromBackendService
  ) {}

  private handlingError(error: any): Observable<any> {
    this.errorListFromBackendService.showError(error);
    return of(error);
  }

  getDashBoardValues(): Observable<DashboardResponseDto | null> {
    return this.scheduleEventResourceService.searchDashboardDataDetails();
  }

  getAllEvents(pagingRequestWithCriteria: SecheduleSearchInput): Observable<ScheduleEventDetailsResponse | null> {
    const dto = toScheduleSearchInputDto(pagingRequestWithCriteria);
    if (!dto) {
      return of(null);
    }
    return this.scheduleEventResourceService.searchScheduleEvents(dto);
  }

  getAllEventsPaged(pagingRequestWithCriteria: SecheduleSearchInput, itemsByPage: number): Observable<ScheduleEventPagingResultDto | null> {
    const dto = toScheduleSearchPageInputDto(pagingRequestWithCriteria, itemsByPage);
    if (!dto) {
      return of(null);
    }
    return this.scheduleEventResourceService.searchScheduleWithPagination(dto);
  }

  regenerateSchedules(scheduleEvent: RegenerateSchedulesModel): Observable<ScheduleEventResponse | null> {
    const dto = toRegenerateSchedules(scheduleEvent);
    if (!dto) {
      return of(null);
    }
    return this.scheduleEventResourceService.updateEvents(dto);
  }

  createEvent(scheduleEvent: EventModel, ipaCode: number, isInternationalISIN: boolean): Observable<EventDetailsResponse | null> {
    const dto = toCreateEventDto(scheduleEvent, ipaCode);
    if (!dto) {
      return of(null);
    }
    let dtoEventDetail: number[];
    let dtoPosition: CreatePositionModel;
    return this.scheduleEventResourceService.createEvents(dto).pipe(
      tap((result: ScheduleEventResponse) => {
        if (result.schedules && result.schedules[0].eventType?.valueDescription === EVENT_CONSTANTS.eventTypes.PCAL) {
          const message = `Event Reference Id: ${result.schedules[0].eventReference?.toString()} `;
          this.toastManagerService.toastSuccess('toasts.events.create.title', message, undefined, result);
          return;
        }
        if (result && result.schedules) {
          dtoEventDetail = toCreateEventDetails(result.schedules[0].eventReference!);
          dtoPosition = {
            isin: scheduleEvent.isin,
            eventId: result.schedules[0].eventReference ?? -1,
            pullFromCommonDepository: isInternationalISIN
          };
        } else {
          this.toastManagerService.toastWarning('toasts.events.duplicate.title', 'toasts.events.duplicate.message', undefined, result);
        }
      }),
      mergeMap(() =>
        this.eventDetailsResourceService.createEventDetails(dtoEventDetail).pipe(
          mergeMap(result => {
            return this.eventsPositionService.createPosition(dtoPosition).pipe(
              map(() => {
                return result;
              })
            );
          })
        )
      ),

      catchError(error => this.handlingError(error))
    );
  }

  deleteEvent(eventId: string) {
    return this.scheduleEventResourceService.deleteEvents(eventId).pipe(
      tap((result: ScheduleEventResponse) => {
        if (result) {
          const message = `Event Reference Id: ${eventId}`;
          this.toastManagerService.toastSuccess('toasts.events.delete.title', message, undefined, result);
        }
      })
    );
  }

  getCompleteEventDetails(eventReferenceId: number, instrumentId?: number): Observable<EventDetailModel | null> {
    const dto = toEventDetailsSearchDto(eventReferenceId);
    if (!dto) {
      return of(null);
    }
    if (instrumentId) {
      const syncDto = {
        instrumentId: instrumentId
      };
      return this.eventDetailsResourceService.syncEventDetails(syncDto).pipe(
        mergeMap(res => {
          if (res.statusCode?.toString() === 'ACCEPTED') {
            return this.getEventData(dto, eventReferenceId);
          }
          this.toastManagerService.toastWarning('toasts.events.eventSyncFailure.title', 'toasts.events.eventSyncFailure.message', undefined, res);
          return this.getEventData(dto, eventReferenceId);
        })
      );
    }
    return this.getEventData(dto, eventReferenceId);
  }

  private getEventData(dto: EventDetailsSearchInput, eventReferenceId: number): Observable<EventDetailModel | null> {
    return this.scheduleEventResourceService.searchScheduleEvents(dto).pipe(
      mergeMap(response => {
        if (response && response.scheduleMap) {
          const instrumentId = response.scheduleMap[eventReferenceId].instrumentId;
          return this.instrumentResourceService.getInstrumentById(instrumentId).pipe(
            map(instrument => {
              const instrumentData = toInstrumentDataModel(instrument);
              return setEventDetails(instrumentData, response, eventReferenceId);
            })
          );
        }
        return of(null);
      })
    );
  }

  updateAdjQty(eventDetails: EventDetailModel): Observable<any | null> {
    const eventDto = toUpdateEventDto(eventDetails);
    return this.eventDetailsResourceService.updateEventDetails(eventDto!);
  }

  updateEventDetails(eventDetails: EventDetailModel): Observable<any | null> {
    let eventDto: EventDetails[] | undefined;
    let scheduleEventDto: UpdateScheduleEvent | undefined;
    if (eventDetails.eventType === EVENT_CONSTANTS.eventTypes.EXCH || eventDetails.eventType === EVENT_CONSTANTS.eventTypes.CERT) {
      scheduleEventDto = toUpdateScheduleEventDto(eventDetails);
    } else {
      eventDto = toUpdateEventDto(eventDetails);
      scheduleEventDto = toUpdateScheduleEventDto(eventDetails);
    }

    const response1 = scheduleEventDto ? this.scheduleEventResourceService.updateEvents(scheduleEventDto).pipe(catchError(error => of(error))) : of(null);
    const response2 = eventDto ? this.eventDetailsResourceService.updateEventDetails(eventDto).pipe(catchError(error => of(error))) : of(null);
    return forkJoin([response1, response2]).pipe(
      tap(res => {
        if (!(res[0].status || res[1]?.status)) {
          this.toastManagerService.toastSuccess('toasts.events.update.title', 'toasts.events.update.message', undefined, res);
        } else {
          this.toastManagerService.toastWarning('toasts.events.eventUpdateFailure.title', 'toasts.events.eventUpdateFailure.message', undefined, res);
        }
      })
    );
  }

  updateScheduleDates(eventDates: EventDetailModel): Observable<any | null> {
    const scheduleEventDto = toUpdateScheduleEventDto(eventDates);
    if (!scheduleEventDto) {
      return of(null);
    }
    return this.scheduleEventResourceService.updateEvents(scheduleEventDto).pipe(
      tap(res => {
        if (res.schedules && res.schedules[0]) {
          this.toastManagerService.toastSuccess('toasts.events.update.title', 'toasts.events.update.message', undefined, res);
        } else {
          this.toastManagerService.toastWarning('toasts.events.eventUpdateFailure.title', 'toasts.events.eventUpdateFailure.message', undefined, res);
        }
      }),
      catchError(error => of(error))
    );
  }

  updateEventStatus(eventRef: number, eventStatus: number) {
    const eventCancelUpdateDto = toEventCancelUpdateDto(eventRef, eventStatus);
    return this.scheduleEventResourceService.scheduleStatusUpdate(eventCancelUpdateDto);
  }

  updatePaymentReleaseStatus(eventRef: number, systemType: string) {
    const releasePaymentUpdateDto = toReleasePaymentUpdateDto(eventRef, systemType);
    return this.eventsPositionResourceService.partialPayments(releasePaymentUpdateDto);
  }

  getEventStatusUpdatedUserDetails(eventId: number) {
    const dto = toStausUpdateUserInput(eventId);
    return this.auditResourceService.getStatusUpdatedUserDetails1(dto);
  }

  getRofPaymentDetails(eventId: number): Observable<RequestOfFundsResponseDto> {
    const dto = toRofSearchDto(eventId);
    return this.rofResourceService.findBySearchCriteria(dto);
  }

  public getDocumentContent(docId: string, eventId: number, eventType: string, isin: string) {
    this.documentVersionResourceService.getDocumentContentByVersion(docId, 'last').subscribe(file => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file);
      a.target = '_blank';
      a.download = `${eventType}_${eventId}_${isin}.${file.type.substring(file.type.lastIndexOf('/') + 1)}`;
      a.click();
    });
  }

  getSwifts(eventId: number): Observable<SwiftMessageResponseDto | null> {
    const id = toSwiftSearchDto([eventId]);
    return this.swiftResourceService.searchSwift(id).pipe(
      catchError(err => {
        this.toastManagerService.toastWarning('toasts.events.notificationList.title', 'toasts.events.notificationList.message');
        return throwError(err);
      })
    );
  }

  getPoolFactor(instrumentId: number, skipToastrMessage = false): Observable<PoolFactorHistoryResponseListDto | null> {
    const id = toPoolFactorSearchDto([instrumentId]);
    return this.eventDetailsResourceService.searchPoolFactorHisty(id).pipe(
      catchError(err => {
        if (!skipToastrMessage) {
          this.toastManagerService.toastWarning('toasts.events.poolFactorList.title', 'toasts.events.poolFactorList.message');
        }
        return throwError(err);
      })
    );
  }

  getEcashPayments(eventId: number): Observable<EcashPaymentDetailsResponse | null> {
    const id = toEcashPaymentsSearchDto([eventId]);
    return this.eventEcashPaymentResourceService.searchEcashPaymentDetails(id).pipe(
      catchError(err => {
        this.toastManagerService.toastWarning('toasts.events.eCashPaymentList.title', 'toasts.events.eCashPaymentList.message', undefined);
        return throwError(err);
      })
    );
  }

  exportEventsData(pagingRequestWithCriteria: SecheduleSearchInput): Observable<Blob | null> {
    const dto = toScheduleSearchInputDto(pagingRequestWithCriteria);

    if (!dto) {
      return of(null);
    }
    return this.scheduleEventResourceService.exportEventsData(dto);
  }

  generateEventsReport(): Observable<Blob | null> {
    return this.scheduleEventResourceService.issScheduleEventReports();
  }
}
