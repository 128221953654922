import { Component, Input, OnInit } from '@angular/core';
import { ColDef, FirstDataRenderedEvent, GridApi, GridOptions } from 'ag-grid-community';
import { InstructionModel } from '../model/instruction.model';
import { InstructionColumnDefService } from '../services/instructions-column-def-service';

@Component({
  selector: 'app-instructions-list',
  templateUrl: './instructions-list.component.html'
})
export class InstructionsListComponent implements OnInit {
  @Input() instructions: InstructionModel[] = [];
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef | undefined;
  public gridOptions!: GridOptions;
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
  public sideBar: any = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      }
    ],
    defaultToolPanel: ''
  };
  constructor(private readonly instructionColumnDefService: InstructionColumnDefService) {}
  ngOnInit(): void {
    this.columnDefs = this.instructionColumnDefService.get();

    this.gridOptions = {
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      onToolPanelVisibleChanged: () => {
        this.columnResize();
      },
      onColumnVisible: () => {
        this.columnResize();
      },
      pagination: true,
      suppressPaginationPanel: false,
      paginationPageSize: 20,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      cacheQuickFilter: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      lockPinned: true,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: false,
      sortable: true,
      filter: true,
      menuTabs: ['filterMenuTab'],
      resizable: true
    };
  }

  onGridReady(params: { api: GridApi }): void {
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.addGlobalListener(this.resizeColumn);
    gridApi.setDomLayout('autoHeight');
    gridApi.refreshHeader();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent): void {
    params.api.sizeColumnsToFit();
  }

  private readonly resizeColumn = (event: any) => {
    if (['gridSizeChanged', 'toolPanelVisibleChanged', 'columnVisible', 'rowDataChanged'].includes(event)) {
      this.columnResize();
    }
  };

  private columnResize() {
    setTimeout(() => {
      this.gridOptions.api?.sizeColumnsToFit();
    });
  }
  public exportCsv(): void {
    this.gridOptions.api?.exportDataAsCsv();
  }
}
