/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { BehaviorSubject, Observable } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { ActivatedRoute } from '@angular/router';

export type ToastLevel = 'info' | 'error' | 'warning' | 'success';
export type ToastCss = 'danger' | 'info' | 'primary' | 'success';
export interface Toast {
  id: number;
  timestamp: number;
  title: string;
  detail: string;
  level: ToastLevel;
  innerData?: unknown;
}

type MultiError = HttpErrorResponse | ErrorEvent | Error | unknown;

@Injectable({
  providedIn: 'root'
})
export class ToastManagerService {
  private readonly toastSubject = new BehaviorSubject<Toast[]>([]);
  public get toasts$(): Observable<Toast[]> {
    return this.toastSubject.asObservable();
  }
  constructor(private readonly i18nUtils: I18nUtilsService, private readonly activatedRoute: ActivatedRoute) {}

  private getHash(val: string): number {
    let hash = 0;

    if (val.length === 0) {
      return hash;
    }
    for (let i = 0; i < val.length; i++) {
      const chr = val.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  private emitToast(toast: Toast): boolean {
    const toasts = this.toastSubject.value;
    const idx = toasts.findIndex(t => t.id === toast.id);
    if (idx < 0) {
      const sortedToast = [...toasts, toast].sort((a, b) => a.timestamp - b.timestamp);
      this.toastSubject.next(sortedToast);
      return true;
    }
    return false;
  }

  public deleteToast(toastId: number): void {
    const toasts = this.toastSubject.value;
    const idx = toasts.findIndex(t => t.id === toastId);
    if (idx >= 0) {
      toasts.splice(idx, 1);
      this.toastSubject.next(toasts);
    }
  }

  private createToast(i18nTitle: string, i18nMessage: string, level: ToastLevel, i18nParams?: Record<string, unknown>, innerData?: unknown): Toast {
    const detail = this.i18nUtils.getI18nValue(i18nMessage, i18nParams);
    const title = this.i18nUtils.getI18nValue(i18nTitle, i18nParams);
    const id = this.getHash(`${title}-${detail}-${level}`);
    const timestamp = new Date().valueOf();
    return {
      id,
      title,
      detail,
      level,
      timestamp,
      innerData
    };
  }

  private handleHttpError(error: HttpErrorResponse): void {
    if (error.status === 0 && error.statusText === 'Unknown Error') {
      this.toastError('toasts.errors.serverSide.title', 'toasts.errors.serverSide.message', {}, error.error);
      return;
    }
    if (error.url && error.url.includes('maestro')) {
      this.toastError('toasts.errors.maestro.title', 'toasts.errors.maestro.message', {}, error);
      return;
    }

    const queryString = this.activatedRoute.snapshot.queryParams.query;
    if (error.url && error.url.endsWith('/_searchScheduleEvents') && queryString === undefined) {
      return;
    }

    const title = typeof error.error === 'string' ? error.error : error.error?.title || 'toasts.errors.http-failure.title';
    const details = error.error?.detail || error.error?.message || error.message || 'toasts.errors.http-failure.message';

    if (details.includes('_getMatrixInstrumentRole')) {
      return;
    }
    this.toastError(title, details, {}, error);
  }

  private handleErrorEvent(error: ErrorEvent) {
    this.toastError(
      'toasts.errors.errorEvent.title',
      'toasts.errors.errorEvent.message',
      {
        message: error.message,
        filename: error.filename,
        lineno: error.lineno,
        colno: error.colno
      },
      error
    );
  }

  private handleInnerError(error: Error) {
    this.toastError('toasts.errors.error.title', 'toasts.errors.error.message', { message: error.message }, error);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(error: MultiError) {
    return error instanceof HttpErrorResponse ? this.handleHttpError(error) : undefined;
  }

  private publishToast(i18nTitle: string, i18nMessage: string, level: ToastLevel, i18nParams?: Record<string, unknown>, innerData?: unknown, autoDelete = true): number {
    const toast = this.createToast(i18nTitle, i18nMessage, level, i18nParams, innerData);
    const isEmitted = this.emitToast(toast);
    if (isEmitted && autoDelete) {
      setTimeout(() => {
        this.deleteToast(toast.id);
      }, 10000);
    }
    return toast.id;
  }

  public toastError(i18nTitle: string, i18nMessage: string, i18nParams?: Record<string, unknown>, innerData?: unknown, autoDelete = true) {
    return this.publishToast(i18nTitle, i18nMessage, 'error', i18nParams, innerData, autoDelete);
  }
  public toastSuccess(i18nTitle: string, i18nMessage: string, i18nParams?: Record<string, unknown>, innerData?: unknown, autoDelete = true) {
    return this.publishToast(i18nTitle, i18nMessage, 'success', i18nParams, innerData, autoDelete);
  }
  public toastInfo(i18nTitle: string, i18nMessage: string, i18nParams?: Record<string, unknown>, innerData?: unknown, autoDelete = true) {
    return this.publishToast(i18nTitle, i18nMessage, 'info', i18nParams, innerData, autoDelete);
  }
  public toastWarning(i18nTitle: string, i18nMessage: string, i18nParams?: Record<string, unknown>, innerData?: unknown, autoDelete = true) {
    return this.publishToast(i18nTitle, i18nMessage, 'warning', i18nParams, innerData, autoDelete);
  }
}
